<template>
  <div id="visa-paiement-container">
    <div class="tw-flex tw-flex-row">
      <div class="sm:tw-w-3/12">
        <SignupSecondaryTextComponent class="public-text-register bleu tw-ml-3 dynamic-text">
          <b> Récapitulatif </b>
        </SignupSecondaryTextComponent>
      </div>
      <div class="tw-w-7/12 tw-ml-6">
        <SignupSecondaryTextComponent class="public-text-register bleu tw-ml-3 dynamic-text">
          <b> Ajouter un compte courant </b>
        </SignupSecondaryTextComponent>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-flex-row visa-paiement-sub-container">
      <div class="md:tw-w-3/12 lg:tw-w-3/12 paiement-border-div">
        <SignupSecondaryTextComponent class="public-text-register">
          Date : {{ currentDate }} UTM<br />
          Solution {{ user.plan.salaryRange === '0 - 0' ? `Module sous-traitance` : `${user.plan.salaryRange} salariés` }}<br />
          Abonnement {{ user.type === 'monthly' ? 'mensuel' : 'annuel' }}<br />
          <b>Montant : {{ user.price }} € HT / {{ ((user.price) * 1.2).toFixed(2) }} € TTC</b>
        </SignupSecondaryTextComponent>
      </div>
      <div class="md:tw-w-9/12 lg:tw-w-9/12 tw-flex tw-flex-col tw-space-y-4 tw-px-3">
        <div class="tw-my-6 tw-p-4 tw-rounded-xl" id="sepa-element" style="border: 0.5px solid rgb(50, 50, 93, .5);"></div>
        <div class="tw-mt-4 tw-text-sm tw-font-medium" style="color: #fa755a;" id="sepa-result"></div>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-flex-row tw-justify-end">
      <button class="validate-button" @click="submit" :disabled="isLoading">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import moment from "moment";
import { attachPaymentMethod, getStripePublicKey } from "../../../company/services/appApi";
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: "AddSepaPaymentMethod",
  components: { SignupSecondaryTextComponent },
  props: {
    user: {},
    selectedType: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isLoading: false,
      buttonText: 'validez',
      stripe: null,
      sepaElement: null,
    }
  },
  computed: {
    currentDate() {
      return moment().format('DD/MM/YY - HH:MM')
    },
    authenticatedUser() {
      return this.$store.state.user;
    }
  },
  async mounted() {
    await this.setStripePublicKey();

    const elements = this.stripe.elements();
    const style = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    };

    this.sepaElement = elements.create('iban', {
      supportedCountries: ['SEPA'],
      placeholderCountry: 'FR',
      style
    });

    setTimeout(() => { this.sepaElement.mount('#sepa-element'); }, 1000);
  },
  methods: {
    async setStripePublicKey() {
      try {
        const { data } = await getStripePublicKey();
        this.stripe = await loadStripe(data);
      }
      catch(error) { 
        this.$snackbar.showAlertMessage({
          message: "Une erreur est survenue lors de la récupération de la clé publique Stripe",
          type: "error",
        });
      }
    },
    async submit() {
      this.isLoading = true;
      this.buttonText = 'Chargement...';

      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: this.sepaElement,
        billing_details: {
          name: this.authenticatedUser.firstName + ' ' + this.authenticatedUser.lastName,
          email: this.authenticatedUser.email,
        },
      })

      if (!!paymentMethod && !!paymentMethod.id) {
        try {
          await attachPaymentMethod({ paymentMethodId: paymentMethod.id });
          this.$router.push({ name: 'Company.DashboardPage' });
        }
        catch(error) { 
          this.$snackbar.showAlertMessage({
            message: "Une erreur est survenue lors de l'ajout de la methode de paiement",
            type: "error",
          });
        }
      }

      if (error) document.getElementById('sepa-result').textContent = error.message;
      this.isLoading = false;
      this.buttonText = 'validez';
    }
  }
}
</script>
